@import "~bootstrap/scss/bootstrap";

@import 'theme/colors.scss';
@import 'theme/fonts.scss';

body{
  font-family: 'Univers LT Std';
  color: $ic_dark_grey;
}

h2, h3, h4, h5, h6{
  font-family: 'Agenda Bold';
}

h1 {
  font-family: 'Agenda Bold';
  line-height: 1em;
  font-size: 4.5vw;
}

.website-content{
  padding: 1vw 10vw;
}

.row{
  margin-right: 0px;
  margin-left: 0px;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

a{
  z-index: 1;
}

.circles-background{
  background-image: url('/assets/about_us/circulosmorados.svg'), url('/assets/about_us/circulosmorados2.svg');
  background-repeat: no-repeat, no-repeat;
  background-size: 15vw, 15vw;
}


// xs
@media only screen and (max-width: 575px) {
  .circles-background{
    background-position: 10% 10%, 90% 10%;
    background-size: 15vw, 15vw;
  }
}
// sm
@media only screen and (min-width: 576px) {
  .circles-background{
    background-position: 10% 15%, 90% 15%;
    background-size: 15vw, 15vw;
  }
}
// md
@media only screen and (min-width: 768px) {
  .circles-background{
    background-position: 10% 20%, 90% 20%;
    background-size: 15vw, 15vw;
  }
}
// lg
@media only screen and (min-width: 992px) {
  .circles-background{
    background-position: 10% 35%, 90% 35%;
    background-size: 20vw, 20vw;
  }
}
// xl
@media only screen and (min-width: 1200px) {
  .circles-background{
    background-position: 10% 35%, 90% 35%;
    background-size: 15vw, 15vw;
  }
}