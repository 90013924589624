// Agenda

@font-face{
    font-family: 'Agenda';
    src: url('fonts/Agenda/Agenda-Medium.otf') format('opentype');
    font-style: normal;
}
@font-face{
    font-family: 'Agenda';
    src: url('fonts/Agenda/Agenda-MediumItalic.otf') format('opentype');
    font-style: italic;
}

@font-face{
    font-family: 'Agenda Light';
    src: url('fonts/Agenda/Agenda-Light.otf') format('opentype');
    font-style: normal;
}
@font-face{
    font-family: 'Agenda Light';
    src: url('fonts/Agenda/Agenda-LightItalic.otf') format('opentype');
    font-style: italic;
}

@font-face{
    font-family: 'Agenda Bold';
    src: url('fonts/Agenda/Agenda-Bold.otf') format('opentype');
    font-style: normal;
}

@font-face{
    font-family: 'Agenda Black';
    src: url('fonts/Agenda/Agenda-Black.otf') format('opentype');
    font-style: normal;
}

// Univers LT Std

@font-face{
    font-family: 'Univers LT Std';
    src: url('fonts/Univers/UniversLTStd.otf') format('opentype');
    font-style: normal;
}

@font-face{
    font-family: 'Univers LT Std';
    src: url('fonts/Univers/UniversLTStd-Obl.otf') format('opentype');
    font-style: italic;
}

@font-face{
    font-family: 'Univers LT Std Light';
    src: url('fonts/Univers/UniversLTStd-Light.otf') format('opentype');
    font-style: normal;
}

@font-face{
    font-family: 'Univers LT Std Light';
    src: url('fonts/Univers/UniversLTStd-LightObl.otf') format('opentype');
    font-style: italic;
}

@font-face{
    font-family: 'Univers LT Std Bold';
    src: url('fonts/Univers/UniversLTStd-Bold.otf') format('opentype');
    font-style: normal;
}

@font-face{
    font-family: 'Univers LT Std Bold';
    src: url('fonts/Univers/UniversLTStd-BoldObl.otf') format('opentype');
    font-style: italic;
}

@font-face{
    font-family: 'Univers LT Std Black';
    src: url('fonts/Univers/UniversLTStd-Black.otf') format('opentype');
    font-style: normal;
}

@font-face{
    font-family: 'Univers LT Std Black';
    src: url('fonts/Univers/UniversLTStd-BlackObl.otf') format('opentype');
    font-style: italic;
}