@import '../../theme/colors.scss';
@import '../../theme/fonts.scss';

.HeroSectionComponent {
    .right_side {
        .telephones_image {
            float: right;
        }
    }
    .left_side {
        align-self: center !important;
        .hero_content {
                text-align: center;
                padding-top: 50px;
            .hero_tittle {
                font-size: 3.5rem;
                color: $ic_purple;
            }
            .hero_description {
                font-size: 1.2rem;
                font-family: "Univers LT Std";
                font-size-adjust: initial;
            }
            .btn-lg {
                font-size: 1.5rem;
                border: 3px solid;
                font-family: "Univers LT Std Bold";
                letter-spacing: 0.1em;
                padding: 0.5em;
                padding-left: 1.2em;
                padding-right: 1.2em;
                background-color: $ic_purple;
                color: white;
                border-color: $ic_purple;
                &:hover{
                    background-color: white;
                    color: $ic_purple;
                    border-color: $ic_purple;
                }
            }
        }
    }
}

// xs
@media only screen and (max-width: 575px) {
    .HeroSectionComponent {
        padding-bottom: 15vw;
        .right_side {
            display: none;
        }
        .left_side {
            .hero_content {
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 50px;
                .hero_tittle {
                    font-size: 2.5rem;
                    color: $ic_purple;
                }
                .hero_description {
                    padding: 20px;
                    padding-bottom: 50px;
                    padding-top: 50px;
                }
            }
        }
    }
}
// sm
@media only screen and (min-width: 576px) {
    .HeroSectionComponent {
        padding-bottom: 10vw;
        .right_side {
            display: none;
        }
        .left_side {
            .hero_content {
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 50px;
                .hero_description {
                    padding: 40px;
                    padding-bottom: 50px;
                    padding-top: 50px;
                }
            }
        }
    }
}
// md
@media only screen and (min-width: 768px) {
    .HeroSectionComponent {
        padding-bottom: 7vw;
        .right_side {
            display: none;
        }
        .left_side {
            .hero_content {
                padding-left: 30px;
                padding-right: 30px;
                padding-top: 50px;
                .hero_description {
                    padding: 80px;
                    padding-bottom: 50px;
                    padding-top: 50px;
                }
            }
        }
    }
}
// lg
@media only screen and (min-width: 992px) {
    .HeroSectionComponent {
        .right_side {
            display: inline;
            .telephones_image {
                display: none;
            }
        }
        .large-background{
            min-height: 50vw;
        }
        .large-background:before{
            background-image: url('/assets/Hero/telefonos_hero.svg');
            background-repeat: no-repeat;
            background-size: 35vw;
            background-position: 50% 50%;
            content: ' ';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
            opacity: 0.4;
        }
        .left_side {
            .hero_content {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                .hero_description {
                    padding: 10px;
                    padding-bottom: 50px;
                    padding-top: 40px;
                }
            }
        }
    }
}
// xl
@media only screen and (min-width: 1200px) {
    .HeroSectionComponent {
        padding-bottom: 5vw;
        .right_side {
            display: inline;
            .telephones_image {
                display: block;
                max-width: 70%;
            }
        }
        .large-background{
            background: none;
            min-height: 0;
        }
        .large-background:before{
            background: none;
        }
        .left_side {
            .hero_content {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                .hero_description {
                    padding: 20px;
                    padding-bottom: 80px;
                    padding-top: 80px;
                }
            }
        }
    }
}