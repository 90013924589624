@import '../../theme/colors.scss';
@import '../../theme/fonts.scss';

.PortfolioShowComponent {
    .header {
        padding-left: 5vw;
        padding-right: 5vw;
        padding-bottom: 2vw;
        text-align: left;
    }
    .title {
        padding-bottom: 2vw;
        h1 {
            font-size: 5rem;
        }
    }
    .sub-title {
        margin-bottom: 0.5vw;
        font-family: "Univers LT Std";
        font-size: 1.0rem;
        color: $ic_purple;
    }
    .sub-descrip {
        font-family: "Univers LT Std";
        font-size: 1.3rem;
        font-style: bold;
    }
    .content {
        background-color: $ic_grey_3;
        padding-top: 4vw;
        padding-bottom: 4vw;
        .img {
            text-align: center;
            align-self: center;
            img {
                max-width: 60%;
            }
        }
        .txt {
            font-family: "Univers LT Std";
            font-size: 1.2rem;
            align-self: center;
            text-align: justify;
            padding-right: 4vw;
        }   
    }
}

// xs
@media only screen and (max-width: 575px) {
    .PortfolioShowComponent {
        .img {
            padding-bottom: 4vw;
        }
    }
}
// sm
@media only screen and (min-width: 576px) {
    .PortfolioShowComponent {
        .img {
            padding-bottom: 4vw;
        }
    }
}
// md
@media only screen and (min-width: 768px) {
    .PortfolioShowComponent {
        .img {
            padding-bottom: 0vw;
        }
    }
}
// lg
@media only screen and (min-width: 992px) {
    .PortfolioShowComponent {
        .img {
            padding-bottom: 0vw;
        }
    }
}
// xl
@media only screen and (min-width: 1200px) {
    .PortfolioShowComponent {
        .img {
            padding-bottom: 0vw;
        }
    }
}