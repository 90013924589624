@import '../../theme/colors.scss';

.portfolio-background {
    background-color: $ic_purple;
    color: white;
    .quote-container{
        text-align: left;
        .quotation-mark{
            font-family: 'Agenda Bold';
            float: left;
        }
        .quote{
            font-family: 'Agenda Light';
        }
        .quote-divider{
            display: inline-block;
            vertical-align: top;
        }
    }
    .author{
        margin: 0;
    }
    .author-name{
        font-family: 'Univers LT Std Bold';
    }
    .author-title{
        font-family: 'Univers LT Std';
        font-style: italic;
    }
}

.carousel-caption{
    left: 0;
    width: 100%;
}

.carousel-indicators > li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

// xs
@media only screen and (max-width: 575px) {
    .quote-container{
        padding: 10vw 8vw;
        .quotation-mark{
            font-size: 5rem;
            transform: translateY(-2rem);
        }
        .quote{
            font-size: 2rem;
            margin-bottom: 30vw;
        }
        .quote-divider-right{
            width: 70%;
        }
    }
    .carousel-caption{
        padding-left: 10vw;
        padding-right: 10vw;
    }
}
// sm
@media only screen and (min-width: 576px) {
    .quote-container{
        padding: 10vw 8vw;
        .quotation-mark{
            font-size: 6rem;
            transform: translateY(-2rem);
        }
        .quote{
            font-size: 3rem;
            margin-bottom: 20vw;
        }
        .quote-divider-right{
            width: 70%;
        }
    }
    .carousel-caption{
        padding-left: 10vw;
        padding-right: 10vw;
    }
}
// md
@media only screen and (min-width: 768px) {
    .quote-container{
        padding: 10vw 8vw;
        .quotation-mark{
            font-size: 6rem;
            transform: translateY(-2rem);
        }
        .quote{
            font-size: 3rem;
            margin-bottom: 8vw;
        }
        .quote-divider-right{
            width: 70%;
        }
    }
    .carousel-caption{
        padding-left: 10vw;
        padding-right: 10vw;
    }
}
// lg
@media only screen and (min-width: 992px) {
    .quote-container{
        padding: 10vw 8vw;
        .quotation-mark{
            font-size: 6rem;
            transform: translateY(-2rem);
        }
        .quote{
            font-size: 3rem;
            margin-bottom: 5vw;
        }
        .quote-divider-right{
            width: 70%;
        }
    }
    .carousel-caption{
        padding-left: 10vw;
        padding-right: 10vw;
    }
}
// xl
@media only screen and (min-width: 1200px) {
    .quote-container{
        padding: 10vw 5vw 0 5vw;
        .quotation-mark{
            font-size: 10rem;
            transform: translateY(-4rem);
        }
        .quote{
            font-size: 4.5rem;
            margin-bottom: 14vw;
        }
    }
    .carousel-caption{
        padding-left: 10vw;
        padding-right: 10vw;
    }
}