@import '../../theme/colors.scss';

.OurServicesSectionComponent {
    .content {
        padding: 5vw 1vw;
        width: 100%;
    }
    h2 {
        font-family: "Univers LT Std Light";
        font-style: bold;
        color: $ic_turquoise;
        font-size: 1.8rem;
        font-weight: bold;
    }
    h1 {
        color: $ic_purple;
    }
    .our-services {
        text-align: center;
        padding-top: 5vw;
        .title {
            padding-top: 2vw;
            padding-bottom: 2vw;
            font-size: 2.5rem;
        }
        p {
            padding-left: 1vw;
            padding-right: 1vw;
            font-family: "Univers LT Std";
            font-style: bold;
            font-size: 1.3rem;
        }
    }
    img {
        width: 40%;
    }
    .creation-img {
        width: 30%;
    }
    .packages-img {
        width: 30%;
    }
}

// xs
@media only screen and (max-width: 575px) {
    .OurServicesSectionComponent{
        padding-top: 10vw;
        padding-bottom: 10vw;
    }
    .about-us-title{
        word-break: break-word !important;
    }
    .our-services {
        .downside {
            padding-top: 5vw;
        }
        .downside2 {
            padding-top: 5vw;
        }
    }
}

// sm
@media only screen and (min-width: 576px) {
    .OurServicesSectionComponent{
        padding-top: 7vw;
        padding-bottom: 7vw;
    }
    .our-services {
        .downside {
            padding-top: 5vw;
        }
    }
}
// md
@media only screen and (min-width: 768px) {
    .OurServicesSectionComponent{
        padding-top: 6vw;
        padding-bottom: 6vw;
    }
    .our-services {
        .downside {
            padding-top: 5vw;
        }
    }
}
// lg
@media only screen and (min-width: 992px) {
    .OurServicesSectionComponent{
        padding-top: 4vw;
        padding-bottom: 4vw;
    }
    .our-services {
        .downside {
            padding-top: 0vw;
        }
    }
}
// xl
@media only screen and (min-width: 1200px) {
    .OurServicesSectionComponent{
        padding-top: 2.5vw;
        padding-bottom: 2.5vw;
    }
    .our-services {
        .downside {
            padding-top: 0vw;
        }
    }
}