@import '../../theme/colors.scss';
@import '../../theme/fonts.scss';

.AboutUsSectionComponent {
    padding-top: 40px;
    background-color: $ic_purple;
    .title {
        padding-top: 5vw;
        color: white;
        text-align: center;
        padding-right: 5vw;
        padding-left: 5vw;
        font-size: 2.6rem;
    }
    .right_arrow {
        -webkit-transform: scaleX(-1);
        -moz-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
    }
    .description {
        color: white;
        font-family: "Univers LT Std Light";
        font-style: bold;
        padding-bottom: 5vw;
        font-size: 1.2rem;
    }
    .title2 {
        font-size: 1.7rem;
        text-align: center;
        color: white;
        font-family: "Univers LT Std Light";
        font-style: bold;
        padding-top: 1vw;
        padding-right: 5vw;
        padding-left: 5vw;
        padding-bottom: 3vw;
    }
    .subtitles {
        color: white;
        font-family: 'Agenda Bold';
        line-height: 1em;
        font-size: 2.5vw;
        padding-top: 1.5vw;
        padding-bottom: 1.5vw;
        font-size: 2.0rem;
    }
}

// xs
@media only screen and (max-width: 575px) {
    .AboutUsSectionComponent {
        .title{
            padding-right: 3vw;
            padding-left: 3vw;
        }
        .title2{
            padding: 5vw 1vw;
            font-size: 1.3rem;
        }
        .left_arrow {
            width: 7%;
        }
        .right_arrow {
            width: 7%;
        }
        .left-description-col {
            padding-top: 5vw;
            padding-left: 0;
            padding-right: 0;
            .left-description-row {
                padding-left: 0;
                padding-right: 0;
                text-align: center;
                .img {
                    width: 19%;
                }
            }
        }
        .right-description-col {
            padding-left: 0;
            padding-right: 0;
            .right-description-row {
                padding-top: 5vw;
                padding-right: 0;
                padding-left: 0;
                text-align: center;
                .img {
                    width: 16%;
                    padding-bottom: 12px;
                }
            }
        }
    }
}
// sm
@media only screen and (min-width: 576px) {
    .AboutUsSectionComponent {
        .title {
            padding-right: 3vw;
            padding-left: 3vw;
            .span {
                width: 50%;
            }
        }
        .title2{
            padding-right: 3vw;
            padding-left: 3vw;
        }
        .left_arrow {
            width: 5%;
        }
        .right_arrow {
            width: 5%;
        }
        .left-description-col {
            padding-left: 7vw;
            padding-right: 7vw;
            .left-description-row {
                text-align: center;
                .img {
                    width: 16%;
                }
            }
        }
        .right-description-col {
            .right-description-row {
                padding-right: 7vw;
                padding-left: 7vw;
                text-align: center;
                .img {
                    width: 14%;
                    padding-bottom: 12px;
                }
            }
        }
    }
}
// md
@media only screen and (min-width: 768px) {
    .AboutUsSectionComponent {
        .title {
            .span {
                width: 50%;
            }
        }
        .left_arrow {
            width: 4%;
        }
        .right_arrow {
            width: 4%;
        }
        .left-description-col {
            padding-left: 7vw;
            padding-right: 7vw;
            .left-description-row {
                text-align: center;
                .img {
                    width: 38%;
                    padding-bottom: 10px;
                }
            }
        }
        .right-description-col {
            .right-description-row {
                padding-right: 7vw;
                padding-left: 7vw;
                text-align: center;
                .img {
                    width: 28%;
                    padding-bottom: 0px;
                }
            }
        }
    }

}
// lg
@media only screen and (min-width: 992px) {
    .AboutUsSectionComponent {
        .title {
            .span {
                width: 50%;
            }
            padding-right: 4vw;
            padding-left: 4vw;
        }
        .left_arrow {
            width: 4%;
        }
        .right_arrow {
            width: 4%;
        }
        .left-description-col {
            padding-left: 2vw;
            padding-right: 2vw;
            .left-description-row {
                text-align: center;
                .img {
                    width: 36%;
                    padding-bottom: 30px;
                }
            }
        }
        .right-description-col {
            .right-description-row {
                padding-right: 2vw;
                padding-left: 2vw;
                text-align: center;
                .img {
                    width: 33%;
                }
            }
        }
    }
}
// xl
@media only screen and (min-width: 1200px) {
    .AboutUsSectionComponent {
        .title {
            .span {
                width: 50%;
            }
        }
        .left_arrow {
            width: 4%;
        }
        .right_arrow {
            width: 4%;
        }
        .left-description-col {
            padding-left: 7vw;
            padding-right: 7vw;
            .left-description-row {
                text-align: center;
                .img {
                    width: 29%;
                }
            }
        }
        .right-description-col {
            .right-description-row {
                padding-right: 7vw;
                padding-left: 7vw;
                text-align: center;
                .img {
                    width: 24%;
                    padding-bottom: 5px;
                }
            }
        }
    }
}