@import '../../theme/colors.scss';

.background-grey{
    background-color: $ic_dark_grey;
    padding-top: 5vw;
    padding-bottom: 5vw;
    color: white;
    span {
        padding: 1.5vw;
    }
    .ender {
        text-align: center;
    }
}
.footer-contact-links{
    text-decoration: none;
    color: white;
    font-family: 'Univers LT Std';
    &:hover{
        text-decoration: none;
        color: white;
    }
}

// xs
@media only screen and (max-width: 575px) {
    .SocialImgs{
        text-align: center;
        a{
            margin: 0 1vw;
        }
    }
    .FooterSectionComponent {
        img {
            width: 9vw;
            height: 9vw;
        }
    }
    .contact-info{
        margin-top: 3vw;
        text-align: center;
        .contact-data{
            margin-top: 3vw;
            .phone-number{
                margin-top: 1vw;
            }
        }
        .contact-data-2{
            padding: 0 20vw;
        }
    }
}
// sm
@media only screen and (min-width: 576px) {
    .SocialImgs{
        text-align: center;
        a{
            margin: 0 1vw;
        }
    }
    .FooterSectionComponent {
        img {
            width: 6.5vw;
            height: 6.5vw;
        }
    }
    .contact-info{
        margin-top: 3vw;
        text-align: center;
        .contact-data{
            margin-top: 3vw;
            .phone-number{
                margin-top: 0;
            }
        }
        .contact-data-2{
            padding: 0;
        }
    }
}
// md
@media only screen and (min-width: 768px) {
    .SocialImgs{
        text-align: center;
    }
    .FooterSectionComponent {
        img {
            width: 5vw;
            height: 5vw;
        }
    }
    .contact-info{
        margin-top: 3vw;
        text-align: center;
        .contact-data{
            margin-top: 3vw;
            .phone-number{
                margin-top: 0;
            }
        }
        .contact-data-2{
            padding: 0;
        }
    }
}
// lg
@media only screen and (min-width: 992px) {
    .SocialImgs{
        text-align: left;
        padding-left: 1vw;
    }
    .FooterSectionComponent {
        img {
            width: 3.5vw;
            height: 3.5vw;
        }
    }
    .contact-info{
        margin-top: 0;
        text-align: right;
        .contact-data{
            margin-top: 0;
            .phone-number{
                margin-top: 0;
            }
        }
        .contact-data-2{
            margin-top: 2vw;
        }
        .contact-data-2{
            padding: 0;
        }
    }
}
// xl
@media only screen and (min-width: 1200px) {
    .SocialImgs{
        text-align: left;
        padding-left: 1vw;
    }
    .FooterSectionComponent {
        img {
            width: 3vw;
            height: 3vw;
        }
    }
    .contact-info{
        margin-top: 0;
        text-align: right;
        .contact-data{
            margin-top: 0;
            .phone-number{
                margin-top: 0;
            }
        }
        .contact-data-2{
            padding: 0;
        }
    }
}