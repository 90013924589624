@import '../../theme/colors.scss';

.ContactSectionComponent {
    color: white;
    font-family: 'Univers LT Std Light';
    background-color: $ic_dark_grey;
    .title {
        padding-top: 5vw;
        text-align: center;
        padding-right: 5vw;
        padding-left: 5vw;
    }
    .title2 {
        text-align: center;
        font-family: "Univers LT Std Light";
        font-style: bold;
        padding-top: 1vw;
        padding-right: 18vw;
        padding-left: 18vw;
        padding-bottom: 3vw;
    }
    .row {
        background-color: $ic_dark_grey;
        .contact-inputs{
            background-color: transparent;
            border: none;
            width: auto;
            color: white;
        }
        .contact-inputs::placeholder{
            color: #808080;
            text-align: center;
        }
    }
}

h1 {
    font-size: 3.1rem;
}
h2 {
    font-size: 1.2rem;
}
.title {
    font-size: 3.1rem;
    margin-bottom: 0;
}
.title2 {
    font-size: 1.1rem;
    padding-top: 0;
}
.contact-us-text{
    font-size: 1.5rem
}
.contact-inputs{
    font-size: 1rem;
}
.submit-button, .submit-button:active{
    font-family: 'Univers LT Std Bold';
    font-size: 1.5rem;
    background-color: white !important;
    border: 3px solid $ic_dark_grey !important;
    color: $ic_dark_grey !important;
    border-radius: 0.4rem;
    &:hover{
        background-color: $ic_dark_grey !important;
        color: white !important;
        border: 3px solid white !important;
    }
}

form{
    width: 100%;
}

p{
    margin-top: 0;
    margin-bottom: 0;
}

input{
    padding: 0 0.5rem;
    background-color: none !important;
    max-width: 50vw;
    text-overflow: ellipsis;
    &:focus{
        box-shadow: none !important;
        border: none !important;
        outline: none !important;
    }
}

.form-text-1, .form-text-1{
    margin: 1vw 0;
}

// xs
@media only screen and (max-width: 575px) {
    .ContactSectionComponent {
        padding-top: 15vw;
        .title2 {
            padding-right: 1vw;
            padding-left: 1vw;
        }
    }
    .submit-button{
        padding: 3vw 8vw;
        margin-bottom: 5vw;
    }
    .button-col{
        text-align: center;
    }
    .form-row{
        padding-top: 4vw;
        padding-bottom: 4vw;
    }
    .form-text{
        margin-bottom: 10vw;
    }
    .title {
        font-size: 2.5rem;
        margin-bottom: 2vw;
    }
}

// sm
@media only screen and (min-width: 576px) {
    .submit-button{
        padding: 2vw 7.5vw;
    }
    .button-col{
        text-align: center;
    }
    .form-row{
        padding-top: 4vw;
        padding-bottom: 4vw;
    }
    .form-text{
        margin-bottom: 5vw;
    }
}
// md
@media only screen and (min-width: 768px) {
    .submit-button{
        padding: 2vw 6.2vw;
    }
    .button-col{
        text-align: right;
    }
    .form-row{
        padding-top: 3vw;
        padding-bottom: 3vw;
    }
    .form-text{
        margin-bottom: 4vw;
    }
}
// lg
@media only screen and (min-width: 992px) {
    .submit-button{
        padding: 2vw 6.2vw;
    }
    .button-col{
        text-align: right;
    }
    .form-row{
        padding-top: 5vw;
        padding-bottom: 5vw;
    }
    .form-text{
        margin-bottom: 2.5vw;
    }
}
// xl
@media only screen and (min-width: 1200px) {
    .submit-button{
        padding: 1vw 3vw;

    }
    .title{
        font-size: 3rem;
    }
    .title2{
        font-size: 1.5rem;
    }
    .contact-us-text{
        font-size: 2rem;
    }
    .contact-inputs{
        font-size: 1.6rem;
    }
    .button-col{
        text-align: right;
    }
    .form-row{
        padding-top: 3vw;
        padding-bottom: 3vw;
    }
    .form-text{
        margin-bottom: 1.5vw;
    }
}