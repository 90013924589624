@import '../../theme/colors.scss';

.navbar{
    text-transform: lowercase;
    font-family: 'Agenda Bold';
    transition: transform 0.5s;
    transition-timing-function: ease-in-out;
    .navbar-collapse{
        .navbar-nav{
            a{
                margin-top: auto;
                margin-bottom: auto;
                color: $ic_dark_grey;
                &:hover, &:focus{
                    color: $ic_dark_grey;
                }
                &.language-change{
                    text-transform: uppercase;
                }
                span{
                    display: inline-block;
                    transition: transform 0.5s, color 0.5s;
                    transition-timing-function: ease-in-out;
                }
                &:hover > span{
                    &.inverted-i{
                        transform: rotateZ(180deg) translateY(5px) scale(0.8);
                        color: $ic_purple;
                    }
                }
                &:hover > span{
                    &.inverted-c{
                        transform: rotateZ(180deg) translateY(2px);
                        color: $ic_navy_blue;
                    }
                }
                .active-language{
                    color: $ic_purple;
                }
            }
        }
    }
    &.hide{
        transform: translateY(-100%);
    }
}
.header_logo,.navbar-links{
    cursor: pointer;
}

.navbar-toggler{
    border: none;
    color: $ic_dark_grey;
    span{
        background-image: url('/assets/Header/hamburger-menu.svg') !important;
        &:hover{
            color: none;
        }
    }
}

// xs
@media only screen and (max-width: 575px) {
    .navbar{
        padding: 3vw 8vw;
        font-size: 1.3em;
    }
    .header_logo{
        max-width: 55vw;
    }
    .navbar-links{
        margin-left: auto;
        text-align: right;
    }
}
// sm
@media only screen and (min-width: 576px) {
    .navbar{
        padding: 1vw 10vw;
        font-size: 1.3em;
    }
    .header_logo{
        max-width: 35vw;
    }
    .navbar-links{
        margin-left: auto;
        text-align: right;
    }
}
// md
@media only screen and (min-width: 768px) {
    .navbar{
        padding: 1vw 10vw;
        font-size: 1.5em;
    }
    .header_logo{
        max-width: 30vw;
    }
    .navbar-links{
        margin: 0 1vw;
    }
}
// lg
@media only screen and (min-width: 992px) {
    .navbar{
        padding: 1vw 8vw;
        font-size: 1.6em;
    }
    .header_logo{
        max-width: 25vw;
    }
    .navbar-links{
        margin: 0 2vw;
    }
}
// xl
@media only screen and (min-width: 1200px) {
    .navbar{
        padding: 1vw 10vw;
        font-size: 1.4em;
    }
    .header_logo{
        max-width: 18vw;
    }
    .navbar-links{
        margin: 0 3vw;
    }
}