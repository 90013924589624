.Home {
    .wa-float {
        position:fixed;
        width:5vw;
        height:5vw;
        bottom:3vw;
        right:4vw;
        background-color:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        font-size:30px;
        align-items: center; 
        z-index:100;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            display: inline-block;
            vertical-align: middle;
        }
    }
}

// xs
@media only screen and (max-width: 576px) {
    .Home {
        .wa-float {
            bottom:2vw;
            right:2vw;
            width:11.5vw;
            height:11.5vw;
            img {
                width: 6.5vw;
                height: 6.5vw; 
            }
        }
    }
}
// sm
@media only screen and (min-width: 576px) {
    .Home {
        .wa-float {
            width:8vw;
            height:8vw;
            img {
                width: 4vw;
                height: 4vw; 
            }
        }
    }
}

/// md
@media only screen and (min-width: 768px) {
    .Home {
        .wa-float {
            width:6vw;
            height:6vw;
            img {
                width: 3.5vw;
                height: 3.5vw; 
            }
        }
    }
}

// lg
@media only screen and (min-width: 992px) {
    .Home {
        .wa-float {
            width:4vw;
            height:4vw;
            img {
                width: 2vw;
                height: 2vw;
            }
        }
    }
}


//ipad
/* Portrait */
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
        .Home {
            .wa-float {
                right:3vw;
                width:6.5vw;
                height:6.5vw;
                img {
                    width: 3vw;
                    height: 3vw;
                }
            }
        }

}

/* Landscape */
@media only screen 
    and (min-device-width: 768px) 
    and (max-device-width: 1024px) 
    and (orientation: landscape) 
    and (-webkit-min-device-pixel-ratio: 2) {
        .Home {
            .wa-float {
                right:3vw;
                width:4.5vw;
                height:4.5vw;
                img {
                    width: 2.5vw;
                    height: 2.5vw;
                }
            }
        }

}



//ipad pro
/* Portrait */
@media only screen 
    and (min-device-width: 1024px) 
    and (max-device-width: 1024px) 
    and (orientation: portrait) 
    and (-webkit-min-device-pixel-ratio: 2) {
        .Home {
            .wa-float {
                right:3vw;
                width:6vw;
                height:6vw;
                img {
                    width: 3vw;
                    height: 3vw;
                }
            }
        }

}

/* Landscape */
@media only screen 
    and (min-device-width: 1366px) 
    and (max-device-width: 1366px) 
    and (orientation: landscape) 
    and (-webkit-min-device-pixel-ratio: 2) {
        .Home {
            .wa-float {
                right:3vw;
                width:4.5vw;
                height:4.5vw;
                img {
                    width: 2.5vw;
                    height: 2.5vw;
                }
            }
        }

}